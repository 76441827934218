<template>
  <div class="overflow-x-hidden">
    <v-container grid-list-xs class="px-4">
      <div v-if="loading">
        <div class="flex flex-column text-center justify-center my-16">
          <!-- <v-progress-circular indeterminate color="grey"></v-progress-circular> -->
          <h2 class="grey--text source my-auto">Loading ...</h2>
        </div>
      </div>
      <div v-else>
        <v-card
          rounded="lg"
          flat
          style="background-color: transparent"
          class="mb-5"
          id="image"
        >
          <div class="flex flex-column">
            <div class="my-0 flex justify-center">
              <v-img max-width="150" :src="changePic()"></v-img>
            </div>
            <div class="flex flex-column text-center mt-3">
              <div style="font-weight: 700">
                {{ invoice.outlet_name }} | {{ invoice.branch_name }}
              </div>
              <div>
                {{ invoice.invoice_id }}
              </div>
            </div>
          </div>
        </v-card>

        <div class="invoice__card__container">
          <div
            class="invoice__card__header"
            :style="{
              'background-color': isOrderInfoExpand ? '#e0e0e0' : '#F85757',
              color: isOrderInfoExpand ? 'black' : 'white',
            }"
          >
            <div class="invoice__card__title">Order Information</div>
            <div
              class="invoice__card__toggle_icon"
              @click="handleExpandOrderInfo(!isOrderInfoExpand)"
            >
              <v-icon color="red" v-if="isOrderInfoExpand"
                >mdi-chevron-up</v-icon
              >
              <v-icon color="white" v-if="!isOrderInfoExpand"
                >mdi-chevron-down</v-icon
              >
            </div>
          </div>
          <div
            class="invoice__card__content__container"
            v-if="isOrderInfoExpand"
          >
            <div
              class="invoice__card__content_row"
              v-if="invoice.table_name && !isShoplink"
            >
              <div class="invoice__card__content_left">Table No</div>
              <div class="invoice__card__content_right">
                {{ invoice.table_name }}
              </div>
            </div>
            <div class="invoice__card__content_row" v-if="!invoice.table_name">
              <div class="invoice__card__content_left">Outlet Address</div>
              <div class="invoice__card__content_right">
                {{ invoice.pickupAddress.address }}
                {{ invoice.pickupAddress.address2 }}
                {{ invoice.guest.postcode }}
              </div>
            </div>
            <div class="invoice__card__content_row" v-if="!invoice.table_name">
              <div class="invoice__card__content_left">Customer Address</div>
              <div class="invoice__card__content_right">
                {{ invoice.guest.address }}
              </div>
            </div>
            <div class="invoice__card__content_row">
              <div class="invoice__card__content_left">Name</div>
              <div class="invoice__card__content_right">
                {{ invoice.guest.name }}
              </div>
            </div>
            <div class="invoice__card__content_row">
              <div class="invoice__card__content_left">Phone Number</div>
              <div class="invoice__card__content_right">
                {{ invoice.guest.phone }}
              </div>
            </div>
            <div class="invoice__card__content_row">
              <div class="invoice__card__content_left">Email</div>
              <div class="invoice__card__content_right">
                {{ invoice.guest.email }}
              </div>
            </div>
            <div class="invoice__card__content_row">
              <div class="invoice__card__content_left">Order Time</div>
              <div class="invoice__card__content_right">{{ timePickup() }}</div>
            </div>
            <div class="invoice__card__content_row" v-if="invoice.payment">
              <div class="invoice__card__content_left">Order Type</div>
              <div class="invoice__card__content_right">
                {{
                  invoice.payment.pay_method
                    ? invoice.payment.pay_method.replace(/_/g, " ")
                    : "Online Banking"
                }}
              </div>
            </div>
            <div class="invoice__card__content_row">
              <div class="invoice__card__content_left">Collect At/After</div>
              <div class="invoice__card__content_right">{{ timePickup() }}</div>
            </div>
            <div class="invoice__card__content_row">
              <div class="invoice__card__content_left">View Map</div>
              <div class="invoice__card__content_right">
                <div
                  style="color: #f85757"
                  text
                  @click="mapLink(invoice.pickupAddress)"
                >
                  <span class="ml-2">Location</span>
                </div>
              </div>
            </div>
          </div>
          <div
            class="invoice__card__footer__container"
            @click="handleExpandOrderInfo(!isOrderInfoExpand)"
          >
            <div>
              {{ isOrderInfoExpand ? "Hide Information" : "Show Information" }}
            </div>
          </div>
        </div>

        <div class="invoice__card__container">
          <div
            class="invoice__card__header"
            :style="{
              'background-color': isSummaryOrderExpand ? '#e0e0e0' : '#F85757',
              color: isSummaryOrderExpand ? 'black' : 'white',
            }"
          >
            <div class="invoice__card__title">Order Summary</div>
            <div
              class="invoice__card__toggle_icon"
              @click="handleExpandOrderSummary(!isSummaryOrderExpand)"
            >
              <v-icon v-if="isSummaryOrderExpand" color="red"
                >mdi-chevron-up</v-icon
              >
              <v-icon v-if="!isSummaryOrderExpand" color="white"
                >mdi-chevron-down</v-icon
              >
            </div>
          </div>
          <div
            class="invoice__card__content__container"
            v-if="isSummaryOrderExpand"
          >
            <div class="invoice__card__content__table">
              <div class="invoice__card__content__table__header">
                <div class="invoice__card__content__table__header_qty">QTY</div>
                <div class="invoice__card__content__table__header_item">
                  Item
                </div>
                <div class="invoice__card__content__table__header_price">
                  Price
                </div>
              </div>
              <div
                v-for="(item, i) in this.renderInvoiceItem()"
                :key="i"
                class="invoice__card__content__table__row"
              >
                <div class="invoice__card__content__table__row_qty table__bold">
                  {{ item.qty }}
                </div>
                <div
                  class="invoice__card__content__table__row_item table__bold"
                >
                  <div
                    v-for="(title, i) in item.items"
                    :key="i"
                    :class="i == 0 ? 'table__bold' : 'table__grey'"
                  >
                    {{ title }}
                  </div>
                </div>
                <div class="invoice__card__content__table__row_price">
                  <div
                    v-for="(price, i) in item.prices"
                    :key="i"
                    :class="i == 0 ? 'table__bold' : 'table__grey'"
                  >
                    {{ price.toFixed(2) }}
                  </div>
                </div>
              </div>
            </div>
            <divider></divider>

            <div class="invoice__card__content_row">
              <div class="invoice__card__content_left">Subtotal</div>
              <div class="invoice__card__content_right">
                {{
                  invoice.paymentDetail.subtotal
                    ? invoice.paymentDetail.subtotal.toFixed(2)
                    : 0
                }}
              </div>
            </div>
            <div class="invoice__card__content_row" v-if="invoice.voucher">
              <div class="invoice__card__content_left">
                Voucher {{ invoice.voucher.code }}
                {{ invoice.voucher.delivery ? "(Delivery)" : "(Item)" }}
              </div>
              <div class="invoice__card__content_right">
                {{ showVoucher(invoice.voucher) }}
              </div>
            </div>
            <div class="invoice__card__content_row" v-if="invoice.delivery">
              <div class="invoice__card__content_left">Delivery Fee</div>
              <div class="invoice__card__content_right">
                {{ invoice.delivery_amount.toFixed(2) }}
              </div>
            </div>
            <div
              class="invoice__card__content_row"
              v-if="invoice.tax && !invoice.tax.inclusive"
            >
              <div class="invoice__card__content_left">Taxes</div>
              <div class="invoice__card__content_right">
                {{
                  invoice.paymentDetail
                    ? invoice.paymentDetail.taxAmount
                    : invoice.tax.amount.toFixed(2)
                }}
              </div>
            </div>
            <div class="invoice__card__content_row">
              <div class="invoice__card__content_left">Charges</div>
              <div class="invoice__card__content_right">
                {{
                  invoice.paymentDetail.serviceCharges
                    ? invoice.paymentDetail.serviceCharges.toFixed(2)
                    : 0
                }}
              </div>
            </div>
            <div class="invoice__card__content_row">
              <div class="invoice__card__content_left">Grand Total</div>
              <div class="invoice__card__content_right">
                {{
                  invoice.paymentDetail
                    ? invoice.paymentDetail.grandTotal.toFixed(2)
                    : invoice.grand_total.toFixed(2)
                }}
              </div>
            </div>
          </div>
          <div
            class="invoice__card__footer__container"
            @click="handleExpandOrderSummary(!isSummaryOrderExpand)"
          >
            <div>
              {{
                isSummaryOrderExpand ? "Hide Information" : "Show Information"
              }}
            </div>
          </div>
        </div>

        <div class="cart_pages__footer_cta__container">
          <v-btn
            color="red"
            @click="navigateToProduct"
            class="ml-2"
            style="flex: 1"
            dark
            large
            elevation="5"
            rounded-md
          >
            <span> Back to Main Page </span>
          </v-btn>
        </div>
      </div>
    </v-container>
  </div>
</template>
<script>
import dayjs from "dayjs";
import { mapGetters } from "vuex";

export default {
  name: "invoice",

  data() {
    return {
      isShoplink: false,
      colors: ["orange", "orange", "orange", "orange", "orange"],
      oldRating: null,
      hasRating: false,
      isOrderInfoExpand: false,
      isSummaryOrderExpand: false,
      newRating: {
        guest_id: "",
        order_id: "",
        rating: null,
        comment: "",
        title: "",
        fire_id: "",
        publish: false,
      },
      defaultButtonText: "",
      selectedFile: null,
      isSelecting: false,
      image_set: require("@/assets/img/coffee.svg"),
      message: "Order Created",
      status: 1,
      hasProductRating: false,
      step: 1,
      productsReview: {
        guest_id: "",
        order_id: "",
        fire_id: "",
        details: [],
      },
      productRating: [],
      snackbar: false,
    };
  },

  watch: {
    invoice: function () {
      if (this.invoice) {
        this.invoice.cart.map((p) => {
          this.productRating.push({
            product_id: p.id,
            rating: null,
          });
        });
        this.$store.dispatch("fetchReview", this.invoice.cart[0].id);
      }
    },
    productReview: function () {
      this.checkHasRating();
    },
  },

  computed: {
    ...mapGetters({
      franchise: "getFranchise",
      invoice: "getInvoice",
      loading: "getLoading",
      productReview: "getProductReview",
    }),
    buttonText() {
      return this.selectedFile
        ? this.selectedFile.name
        : this.defaultButtonText;
    },
  },

  methods: {
    date(i) {
      return dayjs(i).format("MMM DD, YYYY");
    },
    handleExpandOrderInfo(status) {
      this.isOrderInfoExpand = status;
    },
    handleExpandOrderSummary(status) {
      this.isSummaryOrderExpand = status;
    },
    shopLinkChecked() {
      const getShopInfo = localStorage.getItem("shop_info_invoice");
      if (getShopInfo) {
        const shopInfo = JSON.parse(getShopInfo);
        const { shopType } = shopInfo;
        if (shopType == "SHOP_LINK") {
          // SHOPLINK
          this.isShoplink = true;
        }
      }
    },
    renderInvoiceItem() {
      return this.invoice.cart.map((item) => {
        const qty = item.quantity;
        const items = [
          item.name,
          ...item.modifiers.map((modifier) => modifier.name),
        ];
        const prices = [
          item.unit_price,
          ...item.modifiers.map((modifier) =>
            modifier.amount !== 0 ? modifier.amount : ""
          ),
        ];

        return { qty, items, prices };
      });
      // return [{
      //   qty:1,
      //   items:[
      //     "Beef Enak Banget",
      //     "Add Noddle",
      //     "Test"
      //   ],
      //   prices:["20.00","2.00",'']
      // }]
    },
    navigateToProduct() {
      const getShopInfo = localStorage.getItem("shop_info_invoice");
      if (getShopInfo) {
        const shopInfo = JSON.parse(getShopInfo);
        const { shopType } = shopInfo;
        const currentURL = window.location.origin;
        if (shopType == "STATIC_QR") {
          // STATIC QR
          window.location.replace(
            currentURL +
              "/" +
              shopInfo.code +
              "/" +
              shopInfo.outlet_id +
              "/" +
              shopInfo.table_id
          );
        } else if (shopType == "SESSION_QR") {
          // SESSION QR
          window.location.replace(currentURL + "/session/" + shopInfo.session);
        } else {
          // SHOP_LINK
          window.location.replace(currentURL + "/" + shopInfo.code);
        }
        this.$store.dispatch("updateCart", []);
        localStorage.removeItem("cart_list");
      }
    },

    showUnitPrice(v) {
      let mods = 0;

      if (v.modifiers.length > 0) {
        mods = v.modifiers.reduce((sum, item) => (sum = sum + item.amount), 0);
      }

      return mods != 0 ? v.unit_price - mods : v.unit_price;
    },

    showMod(mod) {
      var prefix = mod.mode == 1 ? "+ " : mod.mode == 2 ? "- " : "",
        suffix = mod.mode == 3 ? "% " : "";
      var amt =
        mod.amount > 0 ? ` ${prefix} RM${mod.amount.toFixed(2)}${suffix}` : "";
      return `${mod.name}${amt}`;
    },

    showAmt(vars) {
      if (typeof vars.price == "string") {
        vars.price = parseFloat(vars.price);
      }

      return `${vars.name}`;
    },

    genColor(i) {
      return this.colors[i];
    },

    onButtonClick() {
      this.isSelecting = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );

      this.$refs.uploader.click();
    },

    onFileChanged(e) {
      this.selectedFile = e.target.files[0];

      // do something
    },

    postReview() {
      // console.log(this.newRating)
      if (this.newRating.rating == null) {
        alert("Please give a star rating");
      } else {
        this.newRating.guest_id = this.invoice.guest._id;
        this.newRating.order_id = this.invoice._id;
        this.newRating.fire_id = this.invoice.fire_id;
        this.newRating.publish = true;
        // console.log(this.newRating)
        this.$store.dispatch("saveReview", this.newRating);
        this.hasRating = true;
        this.oldRating = this.newRating.rating;
      }
    },

    postProductReview() {
      this.productsReview.guest_id = this.invoice.guest._id;
      this.productsReview.order_id = this.invoice._id;
      this.productsReview.fire_id = this.invoice.fire_id;
      if (this.invoice.cart.length == this.productRating.length) {
        this.productsReview.details = this.productRating;
        this.$store.dispatch("saveProductsReview", this.productsReview);
        this.hasRating = true;
        this.snackbar = true;
      }
    },

    checkHasRating() {
      this.productReview.map((p) => {
        if (p.order === this.invoice._id) {
          this.hasRating = true;
        }
      });
    },

    timePickup() {
      return dayjs(this.invoice.createdAt)
        .add(15, "minute")
        .format("DD/MM/YYYY h:mmA");
    },
    mapLink(addr) {
      let address = addr.address || "";
      address += addr.address2 || "";
      address += addr.postcode || "";
      // return `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(address)}`;
      window.open(
        `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(
          address
        )}`,
        "_blank"
      );
    },

    changePic() {
      if (this.invoice.status == 1) {
        return require("@/assets/img/order-created.png");
      }
      if (this.invoice.status == 2) {
        return require("@/assets/img/order-created.png");
      }
      if (this.invoice.status == 3) {
        return require("@/assets/img/packing.svg");
      }
      if (this.invoice.status == 4) {
        return require("@/assets/img/order-cancelled.png");
      }
      if (this.invoice.status == 5) {
        return require("@/assets/img/slurp_logo.png");
      }
      if (this.invoice.status == 6) {
        return require("@/assets/img/order-delivery.png");
      }
      if (this.invoice.status == 7) {
        return require("@/assets/img/order-pickup.png");
      }
      if (this.invoice.status == 8) {
        return require("@/assets/img/order-delivered.png");
      }
    },

    changeMessage() {
      switch (this.invoice.status) {
        case 1:
          return "Order Created";
        case 2:
          return "Order Created";
        case 3:
          return "Your order have been accepted";
        case 4:
          return "Your order have been cancelled";
        case 5:
          return "Your order has been accepted and currently being processed";
        case 6:
          return "Your order is currently out for delivery";
        case 7:
          return "Your order is ready to be pickup";
        case 8:
          return "Your order is completed";
      }
    },

    count() {
      return parseFloat(
        this.invoice.cart.reduce((sum, item) => {
          var mods = item.modifiers.reduce((sum, mod) => {
            var amt = parseFloat(mod.amount);
            var actual = mod.mode == 1 ? amt : mod.mode == 2 ? -amt : 0;
            return parseFloat(sum) + actual;
          }, 0);
          return (
            parseFloat(sum) +
            (parseFloat(item.unit_price) + parseFloat(mods)) * item.quantity
          );
        }, 0)
      ).toFixed(2);
    },

    cartTotal(v) {
      let a = v.reduce((sum, item) => {
        return parseFloat(sum) + parseFloat(item.unit_price);
      }, 0);
      return parseFloat(a).toFixed(2);
    },

    showVoucher(v) {
      let prefix;
      if (v) {
        if (v.discount) {
          prefix = `- ${v.discount}%`;
        }
        console.log(this.invoice, "delivery");
        if (v.delivery) {
          // prefix = `- ${parseFloat(v.delivery).toFixed(2)}`;
          prefix =
            v.delivery > this.invoice.total_delivery
              ? `${parseFloat(0.0).toFixed(2)}`
              : `- ${parseFloat(v.delivery).toFixed(2)}`;
        }
        // if (this.voucher.delivery) {
        //   sum -= parseFloat($session.delivery_fees);
        // }
        if (v.deduct) {
          prefix = `- ${parseFloat(v.deduct).toFixed(2)}`;
        }
      }
      return prefix;
    },

    time(i) {
      return dayjs(i).format("h:MM A");
    },

    print() {
      var x = document.getElementById("image");
      var y = document.getElementById("invoice");
      setTimeout(() => {
        x.style.display = "none";
        y.style.display = "block";
        window.print();
        x.style.display = "block";
        y.style.display = "none";
      }, 500);
    },
  },

  beforeMount() {
    this.shopLinkChecked();
    let k = window.location.pathname.split("/");
    let l = k[2].split("%");
    if (k[2].split("%").length > 1) {
      document.location.href = `${window.location.origin}/i/${l[0]}`;
    }
    this.$store.dispatch("startLoad");
    this.$store.dispatch("fetchInvoice", this.$route.params.invoice_id);
    // if (i.cart.modifiers > 0) {
    //   var calcMods = i.cart.reduce(function(sum, item) {
    //       return sum = sum+item.modifiers.amount;
    //   },0);
    // }
    // console.log(calcMods, 'invoice')
    // console.log(this.invoice)
    // if (this.invoice.review) {
    // console.log(this.invoice.review.rating)
    //   this.oldRating = this.invoice.review.rating
    // }
  },
};
</script>
<style lang="scss" scoped>
.cart_pages__footer_cta__container {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  display: flex;
  margin-top: 30px;
  justify-content: space-between;
  align-items: center;
}

.invoice__card__container {
  margin-top: 15px;
  border: 1px solid #c1c1c1;
  border-radius: 10px;

  .invoice__card__header {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    padding: 10px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .invoice__card__title {
      font-weight: 700;
    }
  }

  .invoice__card__content__container {
    .invoice__card__content_row {
      padding-top: 5px;
      padding-left: 10px;
      padding-right: 10px;
      display: flex;
      justify-content: space-between;

      .invoice__card__content_left {
        font-size: 12px;
        font-weight: 600;
        color: #a6a6a6;
        flex: 1;
      }

      .invoice__card__content_right {
        flex: 1;
        font-size: 12px;
        text-align: right;
        font-weight: 700;
      }
    }

    .invoice__card__content__table {
      padding: 10px;

      .invoice__card__content__table__header {
        display: flex;
        font-weight: 600;
        color: #a6a6a6;

        .invoice__card__content__table__header_qty {
          flex: 1;
        }

        .invoice__card__content__table__header_item {
          flex: 4;
        }

        .invoice__card__content__table__header_price {
          flex: 1;
          text-align: right;
        }
      }

      .invoice__card__content__table__row {
        .table__bold {
          font-weight: 700;
        }

        .table__grey {
          font-weight: 600;
          color: #a6a6a6;
        }

        display: flex;

        .invoice__card__content__table__row_qty {
          flex: 1;
        }

        .invoice__card__content__table__row_item {
          flex: 4;
        }

        .invoice__card__content__table__row_price {
          flex: 1;
          text-align: right;
        }
      }
    }
  }

  .invoice__card__footer__container {
    padding: 10px 15px;
    text-align: center;
    color: #f85757;
  }
}
</style>
